header {
  display: flex;
  justify-content: flex-start;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 100px;
}
.visible {
  /* display: flex; */
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  /* display: none; */
  top: -80px;
  transition: top 0.4s ease-out;
}
