.arrow {
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
  width: 15vw !important;
  object-fit: contain !important;
  height: fit-content !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
